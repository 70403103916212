$(document).ready(function () {
	initVideoPopups();
	customAnimateElementsInView();
	initSelectric();
	initBlogLanding();
	initTrees();
	customCookieBar();
	
	// do this last
	initLazyLoading();
});

function initSelectric() {
	$('select').selectric();
}

function initBlogLanding() {
	if( $('.blog-landing').length > 0 ) {
		var numOfItems = $('.blog-landing__grid .blog-landing__item').length;
		if( numOfItems % 2 == 1 ) {
			$('.blog-landing__grid').addClass('right-align');
		}
	}
}

initFeaturedCarousel = function () {
	$('.featured-carousel__inner').each(function () {
		var $el = $(this);

		$el.find('a').on('click', function () {
			$('.featured-carousel__item a').removeClass('current');
			$(this).addClass('current');
			
			$('.featured-carousel__content-item').removeClass('show');
			var $itemToShow = $($(this).attr('href'));
			$itemToShow.addClass('show');

			if (!isElementInViewport($itemToShow)) {
				var scrollOffset = 100;
				if (window.innerWidth > 640) {
					scrollOffset = 400;
				}
				$('html, body').animate({
					scrollTop: $itemToShow.offset().top - scrollOffset
				}, 1000);
			}

			return false;
		});

		$el.on('init', function (event, slick) {
			var $firstLink = $(slick['$slider']).find('[data-slick-index="0"] a');
			$firstLink.addClass('current');
			$($($firstLink).attr('href')).addClass('show');
		});

		$el.slick({
			slidesToShow: 3,
			responsive: [
				{
					breakpoint: 640,
					settings: {
						slidesToShow: 1
					}
				}
			]
		});
	});
}

initHeader = function () {

	function throttle(fn, threshhold, scope) {
	  threshhold || (threshhold = 250);
	  var last,
		  deferTimer;
	  return function () {
		var context = scope || this;

		var now = +new Date,
			args = arguments;
		if (last && now < last + threshhold) {
		  // hold on to it
		  clearTimeout(deferTimer);
		  deferTimer = setTimeout(function () {
			last = now;
			fn.apply(context, args);
		  }, threshhold);
		} else {
		  last = now;
		  fn.apply(context, args);
		}
	  };
	}

	// Detect if the header is over and image slider, if yes then give it a transparent background
	setHeaderTransparency();
	$(window).on('scroll', setHeaderTransparency);

	$('.header').addClass('visible'); // only show the header after the transparency has been set

	function setHeaderTransparency() {
		if (window.pageYOffset < 150) {
			$('.header').removeClass('header--scrolled');
			if ($('.slider, .sliders').length > 0) {
				$('.header').addClass('header--transparent');
			}
		} else {
			$('.header').addClass('header--scrolled');
			$('.header').removeClass('header--transparent');
		}
	}
}



function initLazyLoading() {
	var lazyImages = Array.from($("[data-style], .lazy > source, .lazy-img"));

	var options = {
		root: null,
		rootMargin: "150px 0px",
		threshold: 0,
	  };

	var lazyImageObserver = new IntersectionObserver(callbackObserver, options);


	function presetImgSizeStyles(img) {
		if (
			img.nodeName === "SOURCE" ||
			img.nodeName === "DIV" ||
			img.classList.contains("bg-img") ||
			typeof img.dataset.presets !== "undefined"
		  ) {
			return;
		  }
	  
		  img.style.width = "100%";
		  img.style.height = "auto";
		  img.style.display = "block";
		  img.decoding = "async";
	  }      
	
	function setImageViewportWidth(img) {
		var windowWidth = window.innerWidth;
		var imgWidth = img.clientWidth;
		var imgViewportWidth = Math.floor((imgWidth / windowWidth) * 100) + "vw";
		img.sizes = imgViewportWidth;
	  }

	function handleImgSrcset(img) {
		setImageViewportWidth(img);
		img.srcset = img.dataset.srcset;
		img.src = img.dataset.src;
		delete img.dataset.srcset;
		delete img.dataset.src;
		lazyImageObserver.unobserve(img);
	   
	  }

	function handleImgTag(img) {
		img.src = img.dataset.src;
		delete img.dataset.src;
		lazyImageObserver.unobserve(img);
	}  

	function handleBackgroundImg(img) {
		img.style = img.dataset.style; 
		delete img.dataset;
		lazyImageObserver.unobserve(img);
	  }

	function handlePictureElement(img) {
		if (img.nextElementSibling.nodeName === "IMG") {
		  img.nextElementSibling.src = img.nextElementSibling.dataset.src;
		  delete img.nextElementSibling.dataset.src;
		}
		
		img.srcset = img.dataset.srcset;
		delete img.dataset.srcset;
	
		img.parentElement.classList.remove("lazy");
		lazyImageObserver.unobserve(img);
	}  

	function callbackObserver(entries) {
		entries.forEach(function(entry) {
		  var img = entry.target;
		  
		  if (!entry.isIntersecting) return;
	
		  if (img.parentElement.nodeName === "PICTURE") {
			handlePictureElement(img);
		  }

		  if (img.nodeName === "IMG" && typeof img.dataset.srcset !== 'undefined') {
			handleImgSrcset(img); 
		  }

		  if (img.nodeName === "IMG" && typeof img.dataset.srcset === "undefined") {
			handleImgTag(img); 
		  }

		  if (img.nodeName === "DIV") {
			handleBackgroundImg(img);
		  }

		  lazyImageObserver.unobserve(img);
		});
	  }

	  lazyImages.forEach(function (img) {
		presetImgSizeStyles(img)
	  })
	
	  lazyImages.forEach(function (img) {
		lazyImageObserver.observe(img);
	  });
}



initHome = function () {
	$('.home__events-carousel').on('init', alignHomeEventsArrows);
	$('.home__events-carousel').on('setPosition', alignHomeEventsArrows);
	
	$('.home__events-carousel').slick({
		arrows: true,
		dots: false,
		centerMode: true,
		centerPadding: 0,
		slidesToShow: 3,
		autoplay: true,
		pauseOnHover: false,
		pauseOnDotsHover: true,
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 2
				}
			},
			{
				breakpoint: 640,
				settings: {
					slidesToShow: 1
				}
			}
		]
	});
	
	
	$('.home__row-slider-inner').slick({
		arrows: true,
		dots: false,
		fade: true,
		autoplay: true,
		speed: 1200,
		autoplaySpeed: 3500
	});
	
	
	function alignHomeEventsArrows() {
		$('.home__events .slick-arrow').css('top', ($('.home__events-image img').outerHeight() / 2) + 'px');
	}
}



initLandingSlider = function () {	
	$('.landing__slider-inner').slick({
		arrows: true,
		dots: false,
		fade: true
	});
}


initAccommodation = function () {	
	$('.accommodation__slider-inner').slick({
		arrows: true,
		dots: false,
		fade: true
	});
}



function initVideoPopups() {
	var $modal;
	var modal = '<div id="video-modal" class="gallery-modal gallery-modal--video full reveal" data-reveal data-animation-in="fade-in" data-animation-out="fade-out"><button class="gallery-modal__close" data-close aria-label="Close modal" type="button"><span aria-hidden="true">Close</span></button><div class="gallery-modal__iframe"><iframe frameborder="0" src="{{iframeSrc}}"></iframe></div></div>';

	// Detect video links and embed them in a popup
	$('a[href*="youtu"], a[href*="vimeo.com"]').not('[href*="youtube.com/channel"]').addClass('is-video').on('click', function () {
		$modal = $( modal.replace('{{iframeSrc}}', generateVideoEmbedUrl($(this).attr('href'))) );

		$('body').append($modal);

		$('#video-modal').foundation();
		$modal.foundation('open');

		$modal.on('closed.zf.reveal', function () {
			$modal.remove();
		});

		return false;
	});
}


function generateVideoEmbedUrl(videoUrl) {
	if (videoUrl.indexOf('youtube.com/embed') > -1) {
		// already a YouTube embed code, return same link
		return videoUrl;
	} else if (videoUrl.indexOf('youtu') > -1) {
		// must be either a full or shortened YouTube url so parse and create embed link
		var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
		var match = videoUrl.match(regExp);

		if (match && match[2].length == 11) {
			return 'https://www.youtube.com/embed/' + match[2] + '?rel=0&autoplay=1&modestbranding=1';
		} else {
			return videoUrl;
		}
	} else if (videoUrl.indexOf('player.vimeo.com') > -1) {
		// already a Vimeo embed code, return same link
		return videoUrl;
	} else if (videoUrl.indexOf('https://vimeo.com/') > -1) {
		// normal Vimeo link, so generate and embed code
		return 'https://player.vimeo.com/video/' + videoUrl.split('/')[3] + '?autoplay=true&byline=false&portrait=false&title=false';
	}

	// if all else fails, return the original link
	return embedUrl;
}



initAnimateInView = function () {
	$(window).on('DOMContentLoaded load resize scroll', customAnimateElementsInView);
	customAnimateElementsInView();
	
	$('.animated-border').append('<div class="animated-border-top"></div><div class="animated-border-right"></div><div class="animated-border-bottom"></div><div class="animated-border-left"></div>');
}

isElementInViewport = function(el) {
	var rect = el[0].getBoundingClientRect();
	//var midPoint = rect.top + (rect.height / 2);
	var triggerPoint = rect.top + 100;
	
	
	if ( 
		( triggerPoint >= 0 ) &&
		( triggerPoint <= window.innerHeight )
	) {
		return true;
	} else {
		return false;
	}
}

animateElementsInView = function () {
	// Completely disable this Archi function.	// I need to control when my own version (below) gets fired
}

function customAnimateElementsInView() {
	// Hide the wavy tree from the footer if there's also the social feed
	if ( $('.social').length > 0 ) {
		$('.footer').addClass('no-tree');
	}
	
	
	$('[data-animate-in-view]').each(function () {
		if ( isElementInViewport($(this)) ) {
			$(this).addClass( $(this).data('animate-in-view') ).addClass('in-view');
		}
	});
	
	
	
	var selectorString = '';
	if (typeof projectSettings.applyTypingAnimation === "object") {
		selectorString = projectSettings.applyTypingAnimation.join(',');
	} else {
		selectorString = projectSettings.applyTypingAnimation;
	}
	
	$(selectorString).each(function () {
		if ( isElementInViewport($(this)) ) {
			var delayAmount = 0;
			var delayStep = 1500 / $(this).find('span').length;
			
			if (delayStep == Infinity) {
				delayStep = 15;
			} else if ( delayStep > 50 ) {
				delayStep = 50;
			}
			
			$(this).find('span').each(function () {
				var $span = $(this);
				
				setTimeout(function () {
					$span.css('opacity', 1);
				}, delayAmount);
				
				delayAmount += delayStep;
			});
			
			$(this).addClass('in-view');
		}
	});
}



initOtherRooms = function () {
	var slidesToShow = 3;
	
	if ( $('.other-rooms__carousel').length > 0 ) {
		setOtherRoomsHeight();
		$(window).on('resize', setOtherRoomsHeight);
	}
	
	$('.other-rooms__carousel').each(function () {
		var numberOfRooms = $(this).find('.other-rooms__room').length;
		
		if ( numberOfRooms > 3 ) {
			$(this).addClass('center-mode');
		} else if (numberOfRooms == 1) {
			$(this).addClass('one-room');
			slidesToShow = 1;
		} else if (numberOfRooms == 2 || numberOfRooms == 3) {
			$(this).addClass('two-rooms');
			slidesToShow = 2;
		}
	});
	
	$('.other-rooms__carousel').slick({
		slidesToShow: slidesToShow,
		centerMode: true,
		centerPadding: 0,
		arrows: true,
		dots: false,
		/*prevArrow: '<span class="slick-prev"></span>', 
		nextArrow: '<span class="slick-next"></span>', 
		appendArrows: $('.other-rooms .carousel-nav'), 
		appendDots: $('.other-rooms .carousel-nav'),*/
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 1
				}
			}
		]
	});
}


function setOtherRoomsHeight() {
	// stops the carousel nav from being all jumpy jumpy
	$('.other-rooms__wrapper').css('min-height', $('.other-rooms__room').outerHeight() + 50);
}

var initGallery = function () {
	$('.gallery__item a').on('click', function () {
		var $clickedThumbnail = $(this);
		
		// Dynamically load the gallery slider into a reveal popup	
		var modal = '<div id="gallery__modal" class="gallery__modal full reveal loading" data-reveal><div class="gallery__modal-inner grid-x"><button class="gallery__modal-close" aria-label="Close modal" type="button" data-close><span aria-hidden="true">close</span></button><div class="gallery__modal-image cell medium-10 medium-offset-1"><img src="{{src}}"><button class="gallery__modal-prev">Previous</button><button class="gallery__modal-next">Next</button></div><div class="gallery__modal-content cell medium-10 medium-offset-1 grid-x"><div class="gallery__modal-caption cell small-12 medium-6">{{caption}}</div><div class="gallery__modal-cta cell small-12 medium-6">{{cta}}</div></div></div></div>';
		
		modal = modal.replace('{{src}}', $(this).attr('href'));
		modal = modal.replace('{{caption}}', $(this).data('title') || '');
		
		var cta = $(this).data('description') || '';
		if ( ( typeof $(this).data('book-link') !== 'undefined' ) && ( $(this).data('book-link') !== '' ) ) {
			cta += '<a href="' + $(this).data('book-link') + '" class="button button--white-outline" target="_blank">' + ($(this).data('book-title') || 'Book Now') + '</a>';
		}
		modal = modal.replace('{{cta}}', cta);
		
		var $modal = modal;
		
		$('body').append($modal);
		
		$('#gallery__modal').foundation().on('closed.zf.reveal', function () {
			$('#gallery__modal').remove();
		});
		
		$('#gallery__modal').foundation('open');
		
		$('.gallery__modal-next').on('click', function () {
			$('#gallery__modal').foundation('close');
			$('#gallery__modal').remove();
			if ( $clickedThumbnail.parent().next(':visible').length > 0 ) { // is the next thumb visible?
				$clickedThumbnail.parent().next(':visible').find('a').trigger('click');
			} else if ( $clickedThumbnail.parent().nextUntil(':visible').length > 0 ) { // no, so find the next visible one
				$clickedThumbnail.parent().nextAll(':visible').first().find('a').trigger('click');
				$('#gallery__modal').foundation('close');
				$('#gallery__modal').remove();
			}
		});
		
		$('.gallery__modal-prev').on('click', function () {
			$('#gallery__modal').foundation('close');
			$('#gallery__modal').remove();
			if ( $clickedThumbnail.parent().prev(':visible').length > 0 ) { // is the previous thumb visible?
				$clickedThumbnail.parent().prev(':visible').find('a').trigger('click');
			} else if ( $clickedThumbnail.parent().prevUntil(':visible').length > 0 ) { // no, so find the next visible one that comes before this
				$clickedThumbnail.parent().prevAll(':visible').last().find('a').trigger('click');
				$('#gallery__modal').foundation('close');
				$('#gallery__modal').remove();
			}
		}); 
		
		return false;
	});

	$('.gallery__item').each(function() {
		if( $(this).find('img').attr('width') > 300) {
			$(this).addClass('double-width')
		}

		if( $(this).find('img').height > 300) {
			$(this).addClass('height-2')
		}
	})
	
	refreshMasonry();
	$('.gallery').on('filter-changed', function () {
		setTimeout(refreshMasonry, 600);
	});
	
	Foundation.onImagesLoaded($('.gallery__item img'), refreshMasonry);
	
	function refreshMasonry() {
		$('.gallery__grid').masonry({
			columnWidth: '.gallery__item-sizer',
			itemSelector: '.gallery__item',
			percentPosition: true
		});
	};
	
	// Remove the transition class after loading to avoid jumpy thumbnails
	setTimeout(function () {
		$('.gallery__item').removeClass('transition--2000');
	}, 2000);
	
	
	
	// Remove empty filter buttons (if not handled by the plugin)
	$('.filter__buttons button').each(function () {
		if ( $(this).text() == '' ) {
			$(this).remove();
		}
	});
}

initOffCanvas = function () {
	$('.off-canvas__nav-primary > ul > li').each(function () {
		// Automatically add the "Overview" links to the landing page
		if ( $(this).find('ul').length > 0 ) {
			var overviewLink = $(this).find('a').first().attr('href');
			$(this).find('ul').prepend('<li class="offcanvas__overview-link"><a href="' + overviewLink + '">Overview</a></li>');
		}
	});
	
	
	$('.off-canvas__nav-primary > ul > li > a').on('click', function () {
		if ( $(this).next('.off-canvas__nav-sub').length > 0 ) { // only trigger if there are sub pages to show
			if ( $(this).parent().hasClass('open') ) {
				// second click of main item closes
				$('.off-canvas__nav-primary > ul > li.open').removeClass('open').find('.off-canvas__nav-sub').slideUp();
			} else {
				// close any other open items
				$('.off-canvas__nav-primary > ul > li.open').removeClass('open').find('.off-canvas__nav-sub').slideUp();
				
				// and open the new one
				$(this).parent().addClass('open');
				$(this).next().slideDown();
			}
			return false; // prevent navigating
		}
	});
}


function initTrees() {
	// Pages which end with certain modules need the footer tree to have a different background color
	if (
		($('.offers').length > 0) ||
		($('.blog-detail').length > 0 ) ||
		($('.gallery').length > 0 ) ||
		($('.standard-content').length > 0) ||
		($('.accommodation').length > 0)
	) {
		$('.footer').addClass('solid-tree');
	}
}



initSlider = function () {
	$('.slider__inner').reformSlider(
		settings.slider
	);
		
	if ($('.slider__video').length > 0) {
		// Make sure the slider always fills the whole screen
		coverViewport($('.slider__video iframe'), true);
		$(window).on('resize', function () {
			coverViewport($('.slider__video iframe'), true);
		});
	}
	
	// Add a button for users to scroll down to the content below
	if (settings.sliderFullHeightScrollButton) {
		if ($('.slider').hasClass('slider--full-height')) {
			$('.slider').append('<div class="slider__scroll"><button>Scroll</button></div>');
		}
		$('.slider__scroll button').on('click', function () {
			$('html, body').animate({
				scrollTop: $('.slider').outerHeight() - $('.header').outerHeight()
			}, 1000);
			
			return false;
		});
	}
	
	// If there's no slider, make sure that the content starts below the header
	if ($('.slider').length === 0) {
		pushContentBelowHeaderCustom();
		$(window).on('resize', pushContentBelowHeaderCustom);
	}
}

function pushContentBelowHeaderCustom() {
	$('main').css('padding-top', $('.header').outerHeight());
}



// Archi override
initAccordions = function () {
	var count = 0;
	
	$('.accordion').each(function () {
		if (count == 0) {
			$(this).accordiom({showFirstItem: true});
		} else {
			$(this).accordiom({showFirstItem: false});
		}
		
		count++;
	});
}


function customCookieBar() {
	// Uncomment to always see the cookie bar. Handy for testing!
	// $('.cookie-bar').fadeIn();

	if ( $('.cookie-bar').length > 0 ) {
		if ( !Cookies.get('cookies_accepted') ) {
			$('.cookie-bar').fadeIn();
		}


		$('.cookie-bar__accept').on('click', function () {
			Cookies.set('cookies_accepted', 'true', {expires: 30});
			$('.cookie-bar').fadeOut();
		});
	}
}
