$(document).ready(function () {
	//$('#booking').foundation('open');
	// $('.header__open-booking').on('click', initDateSelection);
});

function initDateSelection() {
	$('#booking').foundation('open');

	// Only load 3rd party iframes when the booking widget is toggled
	$('.booking iframe').each(function () {
		if ( typeof $(this).data('src') !== 'undefined' ) {
			$(this).attr('src', $(this).data('src'));
		}
	});
	$(window).trigger('resize'); // rejig the equalizer for the date fields




	// Open the booking widget and switch to the book table tab
	$('a[href="#bookTable"]').on('click', function () {
		$('#booking').foundation('open');
		$('#book-table-label').trigger('click');
		loadIframes();
		return false;
	});


	// Hide any open date pickers when changing tab
	$('#booking-tabs').on('change.zf.tabs', function () {
		$('.datepicker-container').addClass('datepicker-hide');
	});


	var monthNames = [ "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December" ];
	var dayNames   = [ "Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday" ];

	var bookingContainer = $('.booking__tabs-content-room form');

	var nowTemp = new Date();
	// Don't allow bookings until the hotel opens
	if (nowTemp < new Date('2021-04-15') ) {
		nowTemp = new Date('2021-04-15');
	}


	var tomorrow = new Date();
		tomorrow.setDate(nowTemp.getDate() + 1);

	var $checkInField = bookingContainer.find('.check-in');
	var $checkOutField = bookingContainer.find('.check-out');

	$checkInField.on('hide.datepicker', function () {
		var checkInDate  = $checkInField.datepicker('getDate');
		var checkOutDate = $checkOutField.datepicker('getDate');
		var dayAfterCheckIn = new Date(checkInDate);
			dayAfterCheckIn.setDate(checkInDate.getDate() + 1);

		// Ensure checkout comes after checkin.
		// Otherwise it all gets a bit Restaurant at the End of the Universe-ey.
		if ( checkInDate >= checkOutDate ) {
			$checkOutField.datepicker('setDate', dayAfterCheckIn);
		}

		$checkOutField.datepicker('setStartDate', dayAfterCheckIn);

		$checkOutField[0].focus();
	});

	var checkin = $checkInField.datepicker({
		format: 'dd',
		autoPick: true,
		autoHide: true,
		date: null,
		startDate: nowTemp,
		pick: function (e) {
			// User-facing date fields
			$('.check-in-month-year').text( monthNames[e.date.getMonth()] + ' ' + e.date.getFullYear() );
			$('.check-in-day').text( dayNames[e.date.getDay()] );

			// Formatted date for the booking engine
			$('input[name="arrive"]').val( e.date.getFullYear() + '-' + pad( e.date.getMonth() + 1*1 , 2) + '-' + e.date.getDate()  )
		}
	});

	var checkout = $checkOutField.datepicker({
		format: 'dd',
		autoPick: true,
		autoHide: true,
		date: null,
		startDate: tomorrow,
		pick: function (e) {
			// User-facing date fields
			$('.check-out-month-year').text( monthNames[e.date.getMonth()] + ' ' + e.date.getFullYear() );
			$('.check-out-day').text( dayNames[e.date.getDay()] );

			// Formatted date for the booking engine
			$('input[name="depart"]').val( e.date.getFullYear() + '-' + pad( e.date.getMonth() + 1*1 , 2) + '-' + e.date.getDate() );
		}
	});


	$('.booking select').selectric();


	$('.booking__tabs-content-room-why-book').accordiom({
		showFirstItem: false
	});



}


function pad(n, width, z) {
	z = z || '0';
	n = n + '';
	return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
}
