// This file contains customised Architecture settings.
// Refer to bower_components/Architecture-Blueprint/markup/js/architecture.js for full list of options

var projectSettings = {
	inlineSVG: [
		'.header__mobile-call img',
		'.off-canvas__nav-social img',
		'.header__social img',
		'.footer__social img'
	],
	slider: {
		speed: 6500,
		animationSpeed: 1500,
		arrows: false,
		pagination: false,
		paginationArrows: false
	},
	sliderFullHeightScrollButton: false,
	mapStyle: [{"featureType":"landscape.man_made","elementType":"geometry","stylers":[{"hue":"#C3E0B0"},{"saturation":23},{"lightness":-12},{"visibility":"simplified"}]},{"featureType":"landscape.natural","elementType":"geometry","stylers":[{"saturation":"-12"},{"lightness":"-8"},{"visibility":"simplified"},{"color":"#b1c397"}]},{"featureType":"poi","elementType":"all","stylers":[{"hue":"#A19FA0"},{"saturation":-98},{"lightness":-20},{"visibility":"off"}]},{"featureType":"road","elementType":"geometry","stylers":[{"hue":"#ff0000"},{"saturation":"-100"},{"lightness":"-24"},{"visibility":"simplified"}]},{"featureType":"road","elementType":"labels","stylers":[{"visibility":"off"}]},{"featureType":"water","elementType":"geometry","stylers":[{"saturation":-10},{"lightness":-21},{"visibility":"simplified"},{"color":"#cdeafc"}]}]
};
